/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:0b30b60d-3e66-43ee-adf7-1cf7675ffb22",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_vlQzR8k2T",
    "aws_user_pools_web_client_id": "7mh08vfsph92la19f5oq5cql0p",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://5r7qkhs6dnh2rbhdltcq4plqbi.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
